import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import img1 from '../../../media/blog/ct1.jpeg'
import img2 from '../../../media/blog/ct2.jpeg'
import img3 from '../../../media/blog/ct3.jpeg'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';

function RobotsTxtAndMetaRobotsTags({ sWidth, title, desc, author, date }) {
    const category = 'seo&technical seo'



      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Learn about robots.txt and meta robots tags, their functions, and how to use them effectively to manage your site’s crawlability and indexing. Follow our comprehensive guide to enhance your SEO strategy." />
        <title>Robots txt and Meta Robots Tags: A Comprehensive Guide for SEO</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = '/authors/francesca-romano'}>Francesca Romano</span></h4>
                <p>{date}</p>
            </div>

            <p>Effective management of your site's crawlability and indexing is essential for a successful SEO strategy. Two key tools in this area are the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file and meta robots tags, each serving a distinct role in guiding search engine crawlers. In this guide, we'll delve into these tools, explore their functions, and discuss best practices for their implementation.</p>

<h2>What is Robots.txt?</h2>

<p>The <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file is a crucial component of SEO, located in the root directory of your website. It directs search engine crawlers on which parts of your site they are permitted or forbidden to access.</p>

<p><strong>Why It Matters:</strong> The <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file helps manage your crawl budget and prevents search engines from indexing pages that could negatively impact your SEO or are not intended for public visibility.</p>

<p><strong>How to Implement:</strong></p>
<ul>
    <li><strong>Create the File:</strong> Make a plain text file named <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>robots.txt</span> and upload it to the root directory of your website, e.g., <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>www.example.com/robots.txt</span></li>
    <li><strong>Write Rules:</strong> Use directives like <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>User-agent</span>, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>Disallow</span>, and <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>Allow</span> to manage crawler access. For instance, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>User-agent: Googlebot Disallow: /sensitive-data/</span> prevents Google's crawler from accessing the /sensitive-data/ directory.</li>
    <li><strong>Test Your File:</strong> Utilize tools like Google Search Console’s robots.txt Tester to ensure your directives are correctly configured.</li>
</ul>

<h2>What Are Meta Robots Tags?</h2>

<p>Meta robots tags are HTML elements placed in the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;head&gt;</span> section of your web pages. They give specific instructions to search engines about how to index and follow links on that page.</p>

<p><strong>Why It Matters:</strong> Meta robots tags allow for granular control over individual pages, letting you manage indexing and link-following behaviors precisely.</p>

<p><strong>How to Implement:</strong></p>
<ul>
    <li><strong>Choose Your Directives:</strong> Apply directives like <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>noindex</span>, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>nofollow</span>, and <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>noarchive</span> to dictate page indexing and link-following. For example, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;meta name="robots" content="noindex, nofollow"&gt;</span> prevents search engines from indexing the page and following any links.</li>
    <li><strong>Add to HTML:</strong> Insert the meta robots tag within the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;head&gt;</span> section of your page’s HTML code.</li>
    <li><strong>Test and Monitor:</strong> Verify the application of your tags using tools like Google Search Console to ensure they function as intended.</li>
</ul>

<h2>When to Use Robots.txt vs. Meta Robots Tags</h2>

<p>Deciding whether to use robots.txt or meta robots tags depends on your specific needs:</p>

<ul>
    <li><strong>Robots.txt:</strong> Ideal for blocking entire sections or directories of your site from being crawled. Useful for managing your site's crawl budget and keeping sensitive content from being indexed.</li>
    <li><strong>Meta Robots Tags:</strong> Best for controlling indexing and link-following on a page-by-page basis. Useful for managing content-specific behaviors.</li>
</ul>

<p>Both tools have their unique benefits and can be used together to enhance your site's visibility and indexing. Properly configuring robots.txt and meta robots tags ensures your content is indexed according to your preferences, boosting your SEO efforts.</p>

<h2>Final Thoughts</h2>

<p>Mastering the use of robots.txt and meta robots tags equips you with better control over how search engines interact with your site. This mastery helps optimize your crawl budget, manage indexing, and ultimately strengthen your SEO strategy.</p>

        </MedSection>

        <RightSection>
            <img src={fr} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = '/authors/francesca-romano'}>Francesca Romano</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>
        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = '/authors/francesca-romano'}>Francesca Romano</span></h4>
                <p>{date}</p>
            </div>

            <p>Effective management of your site's crawlability and indexing is essential for a successful SEO strategy. Two key tools in this area are the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file and meta robots tags, each serving a distinct role in guiding search engine crawlers. In this guide, we'll delve into these tools, explore their functions, and discuss best practices for their implementation.</p>

<h2>What is Robots.txt?</h2>

<p>The <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file is a crucial component of SEO, located in the root directory of your website. It directs search engine crawlers on which parts of your site they are permitted or forbidden to access.</p>

<p><strong>Why It Matters:</strong> The <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333" }}>robots.txt</span> file helps manage your crawl budget and prevents search engines from indexing pages that could negatively impact your SEO or are not intended for public visibility.</p>

<p><strong>How to Implement:</strong></p>
<ul>
    <li><strong>Create the File:</strong> <br/>Make a plain text file named <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>robots.txt</span> and upload it to the root directory of your website, e.g., <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>www.example.com/robots.txt</span></li>
    <br/>
    <li><strong>Write Rules:</strong> <br/>Use directives like <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>User-agent</span>, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>Disallow</span>, and <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>Allow</span> to manage crawler access. For instance, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>User-agent: Googlebot Disallow: /sensitive-data/</span> prevents Google's crawler from accessing the /sensitive-data/ directory.</li>
    <br/>
    <li><strong>Test Your File:</strong> <br/>Utilize tools like Google Search Console’s robots.txt Tester to ensure your directives are correctly configured.</li>
    <br/>
</ul>

<h2>What Are Meta Robots Tags?</h2>

<p>Meta robots tags are HTML elements placed in the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;head&gt;</span> section of your web pages. They give specific instructions to search engines about how to index and follow links on that page.</p>

<p><strong>Why It Matters:</strong> Meta robots tags allow for granular control over individual pages, letting you manage indexing and link-following behaviors precisely.</p>

<p><strong>How to Implement:</strong></p>
<ul>
    <li><strong>Choose Your Directives:</strong> <br/>Apply directives like <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>noindex</span>, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>nofollow</span>, and <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>noarchive</span> to dictate page indexing and link-following. For example, <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;meta name="robots" content="noindex, nofollow"&gt;</span> prevents search engines from indexing the page and following any links.</li>
    <br/>
    <li><strong>Add to HTML:</strong> <br/>Insert the meta robots tag within the <span style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f4f4f4", border: "1px solid #ccc", padding: "2px 4px", borderRadius: "4px", fontSize: "0.9em", color: "#333", width: 'fit-content' }}>&lt;head&gt;</span> section of your page’s HTML code.</li>
    <br/>
    <li><strong>Test and Monitor:</strong><br/> Verify the application of your tags using tools like Google Search Console to ensure they function as intended.</li>
    <br/>
</ul>

<h2>When to Use Robots.txt vs. Meta Robots Tags</h2>

<p>Deciding whether to use robots.txt or meta robots tags depends on your specific needs:</p>

<ul>
    <li><strong>Robots.txt:</strong> <br/>Ideal for blocking entire sections or directories of your site from being crawled. Useful for managing your site's crawl budget and keeping sensitive content from being indexed.</li>
    <br/>
    <li><strong>Meta Robots Tags:</strong><br/> Best for controlling indexing and link-following on a page-by-page basis. Useful for managing content-specific behaviors.</li>
    <br/>
</ul>

<p>Both tools have their unique benefits and can be used together to enhance your site's visibility and indexing. Properly configuring robots.txt and meta robots tags ensures your content is indexed according to your preferences, boosting your SEO efforts.</p>

<h2>Final Thoughts</h2>

<p>Mastering the use of robots.txt and meta robots tags equips you with better control over how search engines interact with your site. This mastery helps optimize your crawl budget, manage indexing, and ultimately strengthen your SEO strategy.</p>

        </div>
    </MContainer>
    }

    </>
  )
}

export default RobotsTxtAndMetaRobotsTags


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 700px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: 400;
            margin-left: 10px;
            width: 90%;
        }

        > h3 {
            font-weight: normal;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`