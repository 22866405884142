export const posts = [
    {
        cat: 'search engine marketing',
        title: 'How To Do Keyword Research For Google Ads',
        desc: 'Optimize your Google Ads with our keyword research guide, featuring tools, competitor analysis, and SERP insights',
        author: 'Francesca Romano',
        date: '29 Jul 2024',
        n: 1
    },
    {
        cat: 'seo',
        title: 'How To Measure Keyword Density For Your SEO',
        desc: "Measure keyword density for SEO with our guide. Learn to choose and analyze keywords to ensure optimal usage and improve your content's performance",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 2
    },
    {
        cat: 'seo',
        title: 'How to Perform a Search Engine Crawlability Test for Better SEO',
        desc: "Learn how a search engine crawlability test can improve your website’s SEO by ensuring it is fully indexed and optimized",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 3
    },
    {
        cat: 'seo',
        title: 'How To Perform Backlink Management For Your Website',
        desc: "Enhance your SEO strategy by mastering backlink management with MarketLab’s advanced backlink tools",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 4
    },
    {
        cat: 'social media marketing',
        title: 'How Social Media Affects SEO',
        desc: "Explore the impact of social media on SEO and how you can leverage it to enhance your search engine rankings. Learn effective strategies for optimizing your social presence and driving better SEO results",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 5
    },
    {
        cat: 'seo&digital marketing',
        title: 'Why Is It Important for Marketers to Research the Competition',
        desc: "Learn why understanding your competitors is vital for effective marketing and how to use MarketLab to gain valuable insights",
        author: 'Francesca Romano',
        date: '2 Aug 2024',
        n: 6
    },
    {
        cat: 'seo',
        title: 'How to Find and Optimize Sitemaps for Enhanced SEO',
        desc: "A comprehensive guide on finding and optimizing sitemaps to improve your SEO efforts",
        author: 'Francesca Romano',
        date: '3 Aug 2024',
        n: 7
    },
    {
        cat: 'seo',
        title: 'Technical SEO VS On Page SEO',
        desc: "Discover the key differences between Technical SEO and On-Page SEO and why mastering both is essential for boosting your website’s performance",
        author: 'Francesca Romano',
        date: '5 Aug 2024',
        n: 8
    },
    {
        cat: 'seo',
        title: 'What Next After Completing Keyword Research',
        desc: "Explore essential steps to take after completing keyword research to enhance your SEO strategy",
        author: 'Alvaro Hernandez',
        date: '7 Aug 2024',
        n: 9
    },
    {
        cat: 'seo',
        title: 'Natural Backlinks Ideas',
        desc: "Uncover creative natural backlinks ideas and strategies to elevate your SEO with engaging content and innovative techniques",
        author: 'Alvaro Hernandez',
        date: '12 Aug 2024',
        n: 10
    },
    {
        cat: 'seo&digital marketing',
        title: 'How to Perform Competition Research',
        desc: "Discover how to leverage MarketLab’s tools to conduct effective competition research with detailed visual insights",
        author: 'Francesca Romano',
        date: '18 Aug 2024',
        n: 11
    },
    {
        cat: 'seo&technical seo',
        title: 'Alternate Pages and Proper Canonical Tag Use for SEO',
        desc: "Learn how to manage alternate pages and use canonical tags correctly to enhance your SEO performance",
        author: 'Francesca Romano',
        date: '19 Aug 2024',
        n: 12
    },
    {
        cat: 'seo&technical seo',
        title: 'Robots txt and Meta Robots Tags',
        desc: "Discover how to use robots.txt and meta robots tags to control search engine crawling and indexing, optimizing your SEO strategy with our detailed guide",
        author: 'Francesca Romano',
        date: '19 Aug 2024',
        n: 13
    },
    {
        cat: 'digital marketing',
        title: 'PR In Digital Marketing',
        desc: "Learn how to leverage public relations (PR) in digital marketing to enhance brand visibility, trust, and engagement",
        author: 'Francesca Romano',
        date: '23 Aug 2024',
        n: 14
    },
    {
        cat: 'email marketing',
        title: 'How Can Email Marketing Fuel Your Overall Inbound Strategy',
        desc: "Learn how email marketing can enhance your inbound strategy by nurturing leads and driving conversions",
        author: 'Francesca Romano',
        date: '23 Aug 2024',
        n: 15
    },
    {
        cat: 'email marketing',
        title: 'How to Leverage Email Marketing for B2B Lead Generation',
        desc: "Discover proven strategies to use email marketing for generating high-quality B2B leads and driving business growth",
        author: 'Francesca Romano',
        date: '27 Aug 2024',
        n: 16
    }
]