import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function WhatNextAfterCompletingKeywordResearch({ sWidth, title, desc, author, date }) {
    const category = 'seo'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Learn the crucial actions to take after completing keyword research to effectively implement your SEO strategy. Discover key steps to boost your website’s performance and visibility." />
        <title>What Next After Completing Keyword Research: Key Steps to Take</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'><label onClick={()=> window.location.href = `/blog/category/${category.replaceAll(' ', '-')}`} style={{textTransform: category === 'seo'? 'uppercase': 'capitalize'}}>{category}</label></div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>So, you’ve just wrapped up your keyword research, and you’re feeling pretty proud of yourself. Congrats! <span style={{backgroundColor: '#fcd3d3', fontFamily: 'Quicksand'}}>You’ve done the hard part</span>, but don’t pop the champagne just yet. The keyword party is far from over. In fact, it’s just about to get started. Here’s what you need to do next to make sure all that keyword gold doesn’t just gather dust.</p>

            <h2>Give Your Keywords a Purpose</h2>
            <p>Now that you’ve got a list of keywords, don’t just leave them hanging there like a bunch of lost tourists. Put them to work! Integrate them into your content strategy like they’re VIPs at a celebrity party. Make sure they’re featured prominently in your headlines, subheadings, and body text—<span style={{backgroundColor: '#fcd3d3', fontFamily: 'Quicksand'}}>without stuffing them in awkwardly</span>. Think of it as finding the perfect place for each guest at your party: you don’t want anyone standing in the corner feeling out of place.</p>

            <h2>Create Content That Doesn’t Suck</h2>
            <p>You’ve got the keywords; now let’s talk content. You want to create engaging, high-quality content that not only includes your keywords but also provides value to your audience. Imagine you’re hosting a talk show and your keywords are the guests. You want them to have a great time, share their insights, and leave your audience wanting more. So, write content that’s informative, entertaining, and easy to digest.</p>
            <p> For example, if your keyword is “healthy smoothie recipes,” don’t just list a few recipes. Instead, craft a post that tells a story about how smoothies can transform your health, includes expert tips on selecting ingredients, and adds some quirky smoothie-making hacks. Or, if your keyword is “DIY home office setup,” create a detailed guide with step-by-step instructions, inspiring photos of different setups, and budget-friendly tips. By making your content comprehensive and engaging, you turn your keywords into a valuable resource that attracts and retains readers, rather than just a checklist of terms to be stuffed in here and there.</p>

            <h2>Optimize Like a Pro</h2>
            <p>Next up, it’s time to optimize. This means making sure your keywords are strategically placed in your meta descriptions, title tags, and URLs. Think of these elements as the signposts leading your audience straight to your content. Just like you wouldn’t put a sign for a taco truck in a desert, don’t forget to optimize these areas for the best results.</p>
            <p>For instance, if your primary keyword is “eco-friendly home improvements,” your title tag could be “Top 10 Eco-Friendly Home Improvements for a Greener Lifestyle.” Your meta description might read, “Discover practical and stylish eco-friendly home improvements that reduce your carbon footprint and save you money.” Ensure your URL includes the keyword too, like <span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>/eco-friendly-home-improvements</span>. This approach not only helps search engines understand what your content is about but also entices users to click through by clearly highlighting the value they’ll get.</p>

            <h2>Monitor and Adjust</h2>
            <p>Once your content is live, don’t just sit back and relax. Keep an eye on how it’s performing. Are your keywords working their magic, or are they just lounging around doing nothing? Use tools like Google Analytics to track your performance and make adjustments as needed. It’s like keeping tabs on your party guests to make sure everyone’s having a good time and no one’s causing a scene.</p>
            <p>For example, you might notice that one of your target keywords is attracting lots of traffic but isn’t converting visitors into leads. This could mean that while your content is getting clicks, it might not be addressing the search intent effectively. In this case, you might need to refine your content to better match what users are looking for or adjust your call-to-action. Conversely, if another keyword is driving engagement and leads, consider expanding on that topic or creating related content to capitalize on its success. Regularly reviewing performance metrics helps you fine-tune your strategy, ensuring your content remains effective and continues to meet your audience's needs.</p>
            
            <h2>Celebrate Your Wins</h2>
            <p>Finally, don’t forget to celebrate your successes! If your keywords are bringing in traffic and engaging your audience, give yourself a pat on the back. Maybe even throw a little party for your team. After all, keyword research is hard work, and you deserve to enjoy the fruits of your labor.</p>



            <p>So, there you have it! From putting your keywords to work to optimizing like a pro, these steps will help you make the most of your keyword research. Now go out there and turn those keywords into content that’s worth talking about. And remember, the best parties are the ones where everyone has a great time. Cheers to your keyword success!</p>
        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Alvaro Hernandez is the CMO at Improwth, where he leverages innovative marketing strategies and market insights to drive growth and enhance brand positioning. His leadership has been pivotal in shaping the company's marketing efforts and expanding its market impact.</p>
            <LinkedInIcon onClick={()=> window.open('https://www.linkedin.com/in/alvaro-hernandez-195b7b210', '_blank')}/>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>
        <div className='catDiv'><label onClick={()=> window.location.href = `/blog/category/${category.replaceAll(' ', '-')}`} style={{textTransform: category === 'seo'? 'uppercase': 'capitalize'}}>{category}</label></div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>So, you’ve just wrapped up your keyword research, and you’re feeling pretty proud of yourself. Congrats! <span style={{backgroundColor: '#fcd3d3', fontFamily: 'Quicksand'}}>You’ve done the hard part</span>, but don’t pop the champagne just yet. The keyword party is far from over. In fact, it’s just about to get started. Here’s what you need to do next to make sure all that keyword gold doesn’t just gather dust.</p>

            <h2>Give Your Keywords a Purpose</h2>
            <p>Now that you’ve got a list of keywords, don’t just leave them hanging there like a bunch of lost tourists. Put them to work! Integrate them into your content strategy like they’re VIPs at a celebrity party. Make sure they’re featured prominently in your headlines, subheadings, and body text—<span style={{backgroundColor: '#fcd3d3', fontFamily: 'Quicksand'}}>without stuffing them in awkwardly</span>. Think of it as finding the perfect place for each guest at your party: you don’t want anyone standing in the corner feeling out of place.</p>

            <h2>Create Content That Doesn’t Suck</h2>
            <p>You’ve got the keywords; now let’s talk content. You want to create engaging, high-quality content that not only includes your keywords but also provides value to your audience. Imagine you’re hosting a talk show and your keywords are the guests. You want them to have a great time, share their insights, and leave your audience wanting more. So, write content that’s informative, entertaining, and easy to digest.</p>
            <p> For example, if your keyword is “healthy smoothie recipes,” don’t just list a few recipes. Instead, craft a post that tells a story about how smoothies can transform your health, includes expert tips on selecting ingredients, and adds some quirky smoothie-making hacks. Or, if your keyword is “DIY home office setup,” create a detailed guide with step-by-step instructions, inspiring photos of different setups, and budget-friendly tips. By making your content comprehensive and engaging, you turn your keywords into a valuable resource that attracts and retains readers, rather than just a checklist of terms to be stuffed in here and there.</p>

            <h2>Optimize Like a Pro</h2>
            <p>Next up, it’s time to optimize. This means making sure your keywords are strategically placed in your meta descriptions, title tags, and URLs. Think of these elements as the signposts leading your audience straight to your content. Just like you wouldn’t put a sign for a taco truck in a desert, don’t forget to optimize these areas for the best results.</p>
            <p>For instance, if your primary keyword is “eco-friendly home improvements,” your title tag could be “Top 10 Eco-Friendly Home Improvements for a Greener Lifestyle.” Your meta description might read, “Discover practical and stylish eco-friendly home improvements that reduce your carbon footprint and save you money.” Ensure your URL includes the keyword too, like <span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>/eco-friendly-home-improvements</span>. This approach not only helps search engines understand what your content is about but also entices users to click through by clearly highlighting the value they’ll get.</p>

            <h2>Monitor and Adjust</h2>
            <p>Once your content is live, don’t just sit back and relax. Keep an eye on how it’s performing. Are your keywords working their magic, or are they just lounging around doing nothing? Use tools like Google Analytics to track your performance and make adjustments as needed. It’s like keeping tabs on your party guests to make sure everyone’s having a good time and no one’s causing a scene.</p>
            <p>For example, you might notice that one of your target keywords is attracting lots of traffic but isn’t converting visitors into leads. This could mean that while your content is getting clicks, it might not be addressing the search intent effectively. In this case, you might need to refine your content to better match what users are looking for or adjust your call-to-action. Conversely, if another keyword is driving engagement and leads, consider expanding on that topic or creating related content to capitalize on its success. Regularly reviewing performance metrics helps you fine-tune your strategy, ensuring your content remains effective and continues to meet your audience's needs.</p>
            
            <h2>Celebrate Your Wins</h2>
            <p>Finally, don’t forget to celebrate your successes! If your keywords are bringing in traffic and engaging your audience, give yourself a pat on the back. Maybe even throw a little party for your team. After all, keyword research is hard work, and you deserve to enjoy the fruits of your labor.</p>



            <p>So, there you have it! From putting your keywords to work to optimizing like a pro, these steps will help you make the most of your keyword research. Now go out there and turn those keywords into content that’s worth talking about. And remember, the best parties are the ones where everyone has a great time. Cheers to your keyword success!</p>
        </div>
    </MContainer>
    }

    </>
  )
}

export default WhatNextAfterCompletingKeywordResearch


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 100%;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;

    > div {
        display: flex;
        flex-direction: column;

        > .catDiv {
            width: 100%;
            margin-top: 60px;
            > label {
                font-family: 'Quicksand';
                color: gray;
                margin-left: 5px;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: 400;
            margin-left: 10px;
            width: 90%;
        }

        > h3 {
            font-weight: normal;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`