import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import c1 from '../media/top_clutch.co_seo_company_netherlands_2023.png'
import c2 from '../media/top_clutch.co_seo_company_amsterdam_2023.png'
import x3 from '../media/6.png'
import x4 from '../media/bark-pro-medium.png'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import InsightsIcon from '@mui/icons-material/Insights';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { posts } from './blog/posts'
import BlogCard from './BlogCard'
import fr from '../media/francesca_2.png'
import ah from '../media/alv-2.png'
import logo from '../media/logo.png'
import { Helmet } from 'react-helmet-async'


function Home({ sWidth }) {




    useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Improwth",
            "url": "https://improwth.com",
            "description": "Discover Powerful SaaS Solutions for Digital Marketing. Unlock Valuable Insights and Drive Growth with Our Tools"
        };
    
        const organizationSchema = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Improwth",
            "url": "https://improwth.com",
            "logo": logo,
            "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Service",
                "email": "reach@improwth.com"
            }
        };
    
        // Inject the schema data into the head
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify([schemaData, organizationSchema]);
        document.head.appendChild(script);
    
        return () => {
            document.head.removeChild(script);
          };
    }, [])


      const segmentArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      }


      const segmentedPosts = segmentArray(posts.sort((a, b) => b.n - a.n), 3);

      const mVPosts = posts.sort((a, b) => b.n - a.n)

      
  return (
    <>
    <Helmet>
        <meta name="description" content="Streamline your digital marketing with Improwth. Our intuitive tools and strategies make it easy to achieve effective results and drive your business forward."/>
        <title>Improwth | Make Digital Marketing Easy & Effective</title>
        <link rel="canonical" href="https://improwth.com" />
    </Helmet>

    {sWidth > 768 && <Container>
        <Hero>
            <h1>Navigate Market Waters with Precision</h1>
            <h2>Market Insights That Unravel Growth Secrets</h2>
            <div>
                <button onClick={()=> window.location.href = '/products'}>See our Products</button>
                <button onClick={()=> window.location.href = '/contact'}>Contact Us for Marketing Services Requests</button>
            </div>

            <div className='box1'>
                <img src={c1} alt='Top SEO Company award - Netherlands 2023'/>
                <img src={c2} alt='Top SEO Company award - Amsterdam 2023'/>
                <img src={x3} alt='Best Agency award - 2022'/>
                <img src={x4} alt='Bark professional company'/>
                <img src='https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-blue-light-xl.svg' style={{width: '160px'}} alt='Sortlist verified agency'/>
            </div>
        </Hero>

        <Why>
            <h2>Why Choose our Products</h2>
            <p>Innovation is at the heart of everything we do. With a relentless focus on user experience and cutting-edge technology, 
                our products are designed to deliver tangible results and drive business success. Here's what sets us apart:
            </p>

            <div>
            <div>
                <RemoveRedEyeIcon />
                <h3>Data-Driven Insights</h3>
                <p>Harness the power of data to make smarter decisions and achieve your business objectives</p>
            </div>
            <div>
                <InsertEmoticonIcon />
                <h3>User-Friendly Interface</h3>
                <p>Our intuitive interfaces make it easy for users of all skill levels to access and leverage powerful features</p>
            </div>
            </div>
            
            <div>
            <div>
                <InsightsIcon />
                <h3>Continuous Innovation</h3>
                <p>We're committed to staying ahead of the curve, constantly evolving our products to meet the changing needs of our users</p>
            </div>
            <div>
                <SupportAgentIcon />
                <h3>Exceptional Support</h3>
                <p>Our dedicated team is here to provide expert guidance and support every step of the way, ensuring you get the most out of our products</p>
            </div>
            </div>
            <button onClick={()=> window.location.href = '/products'}>Go to products</button>
        </Why>

        <Products>
        <h2>Our Products</h2>
            <p>At Improwth, we're committed to revolutionizing the way businesses navigate the digital landscape. Our suite of SaaS products is designed to 
                empower you with the insights and tools you need to stay ahead of the competition and unlock new opportunities for growth
            </p>

            <div>
            <div>
                <h3 className='market_lab'>Market Lab</h3>
                <p>Embark on a journey of discovery with Market Lab, your ultimate companion in the ever-evolving realm of digital markets. 
                    Delve into the intricate tapestry of online landscapes as you uncover hidden gems and decode the language of success. From deciphering the enigmatic whispers of keywords to unveiling the clandestine strategies of rivals, Market Lab empowers you to navigate with precision, turning data into insights and insights into victories. With Market Lab, the world becomes your laboratory, and innovation becomes your constant companion. Unleash your curiosity, 
                    ignite your strategy, and chart a course to boundless possibilities with Market Lab.</p>
            </div>
            <button onClick={()=> window.open('https://marketlab.improwth.com', '_blank')}>Go to Market Lab</button>
            </div>
        </Products>


        <RecentBlogPosts>
            <h2>Recent Blog Posts</h2>
        {segmentedPosts?.map(ps=> {
            return (<div>
            {ps.map(post=> {
                return (
                    <BlogCard
                        key={post.title}
                        cat = {post.cat}
                        title = {post.title} 
                        desc = {post.desc}
                        author = {post.author}
                        date = {post.date}
                    />
                )
            })}
        </div>)
        })}
        </RecentBlogPosts>
    </Container>}

    {sWidth <= 768 && 
    <MContainer>
        <MHero>
        <h1>Navigate Market Waters with Precision</h1>
        <h2>Market Insights That Unravel Growth Secrets</h2>

        <div className='awards'>
            <img src={c1} alt='Top SEO Company award - Netherlands 2023'/>
            <img src={c2} alt='Top SEO Company award - Amsterdam 2023'/>
            <img src={x3} alt='Best Agency award - 2022'/>
        </div>

        <div className='btns'>
            <button onClick={()=> window.location.href = '/products'}>See our products</button>
        </div>
        </MHero>

        <MWhy>
            <h2>Why Choose our Products</h2>
            <p>Innovation is at the heart of everything we do. With a relentless focus on user experience and cutting-edge technology, 
                our products are designed to deliver tangible results and drive business success. Here's what sets us apart:
            </p>

            <div>
                <RemoveRedEyeIcon />
                <h3>Data-Driven Insights</h3>
                <p>Harness the power of data to make smarter decisions and achieve your business objectives</p>
            </div>
            <div>
                <InsertEmoticonIcon />
                <h3>User-Friendly Interface</h3>
                <p>Our intuitive interfaces make it easy for users of all skill levels to access and leverage powerful features</p>
            </div>
            <div>
                <InsightsIcon />
                <h3>Continuous Innovation</h3>
                <p>We're committed to staying ahead of the curve, constantly evolving our products to meet the changing needs of our users</p>
            </div>
            <div>
                <SupportAgentIcon />
                <h3>Exceptional Support</h3>
                <p>Our dedicated team is here to provide expert guidance and support every step of the way, ensuring you get the most out of our products</p>
            </div>
            <button onClick={()=> window.location.href = '/products'}>Go to products</button>
        </MWhy>

        <MProducts>
            <h2>Our Products</h2>
            <p>At Improwth, we're committed to revolutionizing the way businesses navigate the digital landscape. Our suite of SaaS products is designed to 
                empower you with the insights and tools you need to stay ahead of the competition and unlock new opportunities for growth
            </p>

            <div>
                <h3 className='market_lab'>Market Lab</h3>
                <p>Embark on a journey of discovery with Market Lab, your ultimate companion in the ever-evolving realm of digital markets. 
                    Delve into the intricate tapestry of online landscapes as you uncover hidden gems and decode the language of success. From deciphering the enigmatic whispers of keywords to unveiling the clandestine strategies of rivals, Market Lab empowers you to navigate with precision, turning data into insights and insights into victories. With Market Lab, the world becomes your laboratory, and innovation becomes your constant companion. Unleash your curiosity, 
                    ignite your strategy, and chart a course to boundless possibilities with Market Lab.</p>
                <button onClick={()=> window.open('https://marketlab.improwth.com', '_blank')}>Go to Market Lab</button>
            </div>
        </MProducts>

        <MRecentBlogPosts>
            <h2>Recent Blog Posts</h2>
            {mVPosts.map(post => (
            <div style={{margin: '20px 5px'}}>
            <h2 onClick={()=> window.location.href = `/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`}  style={{fontFamily: 'Quicksand', color: 'blue'}}>{post.title}</h2>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: '13px'}}>
                <img src={post.author === 'Francesca Romano'? fr: post.author === 'Alvaro Hernandez'? ah: ''} alt={post.author} style={{borderRadius: '50%'}} width={37} />
                <p style={{fontFamily: 'Quicksand', fontSize: 'small'}} onClick={() => window.location.href = `/authors/${post.author.toLowerCase().replaceAll(' ', '-')}`}>{post.author}</p>
                <p style={{marginLeft: 'auto'}}>{post.date}</p>
            </div>
            </div>
        ))}
        </MRecentBlogPosts>

    </MContainer>}
    </>
  )
}

export default Home


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
`

const FadeEffect = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Hero = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    height: fit-content;

    > h1 {
        font-family: 'Fira Sans';
        font-size: 50px;
        background-image: linear-gradient(to right, black, blue);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    > h2 {
        font-weight: 200;
    }

    > div {
        display: flex;
        align-items: center;
        gap: 120px;

        > button {
            border: none;
            border-radius: 3px;
            background-color: #213571;
            transition: background-color 0.3s ease-in-out;
            padding: 10px 15px;
            cursor: pointer;
            color: #f2f2f2;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;

            &:hover {
                background-color: #f2f2f2;
                color: #213571;
            }
        }
    }

    > .box1 {
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: center;
        margin-top: 0px;
        width: 100%;
        justify-content: center;

        opacity: 0;
        animation: ${FadeEffect} 0.4s ease-in-out forwards;

        > img {
            width: 130px;
        }
    }
`

const Why = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    height: 100%;
    width: 100%;
    margin: 160px 20px 20px 20px;
    padding-block: 60px 160px;
    background: radial-gradient(at center, #add8e69e 0%, transparent 50%);

    > h2 {
        font-weight: 300;
    }

    > p {
        width: 90%;
    }

    > div {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        padding-inline: 20px;
    }

    > div > div {
        display: flex;
        flex-direction: row;
        gap: 35px;
        align-items: center;
        width: 50%;
        word-wrap: break-word;
        padding-inline: 30px;

        > h3 {
            width: 220px;
        }
    }

    > button {
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;
        background-color: darkblue;
        color: white;
        width: fit-content;
        transition: background-color 0.3s ease-in-out;
        margin: 50px 0 0 50px;

        &:hover {
            background-color: white;
            color: darkblue;
        }
    }
`

const Products = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    height: 100%;
    width: 100%;
    margin: 160px 20px 20px 20px;
    padding-block: 60px 160px;
    font-family: 'Crimson Text';

    > h2 {
        font-weight: 300;
        font-family: 'Crimson Text';
    }

    > p {
        width: 90%;
        font-family: 'Crimson Text';
        color: gray;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        padding-inline: 20px;

        > button {
            border: none;
            border-radius: 3px;
            padding: 5px 10px;
            cursor: pointer;
            background-color: darkblue;
            color: white;
            width: fit-content;
            transition: background-color 0.3s ease-in-out;
            margin: 50px 0 0 50px;
            align-self: flex-start;

            &:hover {
                background-color: white;
                color: darkblue;
            }
        }
    }

    > div > div {
        display: flex;
        flex-direction: row;
        gap: 35px;
        align-items: center;
        width: 90%;
        word-wrap: break-word;
        padding-inline: 30px;

        > h3 {
            width: 390px;
        }

        > .market_lab {
            font-family: 'Crimson Text';
            background-image: linear-gradient(to right, darkblue, crimson, darkblue);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-weight: 300;
        }

        > p {
            font-family: 'Crimson Text';
            font-weight: 300;
        }
    }
`


const RecentBlogPosts = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 25px;
    gap: 20px;
    align-items: center;
    padding-bottom: 200px;

    > h2 {
        font-weight: 300;
        font-family: 'Quicksand';
        margin: 10px 0 25px 20px;
        width: 100%;
        align-self: start;
    }

    > div {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }
`

const MContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const MHero = styled.div`
    display: flex;
    flex-direction: column;
    padding: 90px 0 130px 10px;
    gap: 10px;
    height: fit-content;

    > h1 {
        font-family: 'Fira Sans';
        font-size: 30px;
        background-image: linear-gradient(to right, black, blue);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    > h2 {
        font-family: 'Fira Sans';
        font-size: 20px;
        background-image: linear-gradient(to right, black, blue);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    > .awards {
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: fit-content;

        > img {
            object-fit: contain;
            width: 70px;
        }
    }

    > .btns {
        width: 100%;
        display: flex;
        margin-top: 55px;
        justify-content: center;
        align-items: center;
        
        button {
            border: none;
            border-radius: 3px;
            padding: 5px 10px;
            cursor: pointer;
            background-color: darkblue;
            color: white;
            width: fit-content;
            transition: background-color 0.3s ease-in-out;
            align-self: center;
        }
    }
`

const MWhy = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    > h2 {
        font-weight: 300;
    }

    > p {
        width: 100%;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        margin-top: 35px;

        > div {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            > h3 {
                width: 100%;
                text-align: center;
            }

            > p {
                width: 100%;
                text-align: center;
            }
        }
    }

    > button {
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;
        background-color: darkblue;
        color: white;
        width: fit-content;
        transition: background-color 0.3s ease-in-out;
        align-self: center;
        margin-top: 20px;

        &:hover {
            background-color: white;
            color: darkblue;
        }
    }
`

const MProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-top: 160px;
    padding-bottom: 100px;

    > h2 {
        font-weight: 300;
    }

    > p {
        width: 100%;
        color: gray;
        font-family: 'Crimson Text';
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-block: 45px;

        > h3 {
            font-family: 'Crimson Text';
            background-image: linear-gradient(to left, darkblue 30%, crimson 60%, darkblue);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-weight: 300;
            text-align: center;
        }

        > p {
            font-family: 'Crimson Text';
            font-weight: 300;
            text-align: left;
        }

        > button {
            border: none;
            border-radius: 3px;
            padding: 5px 10px;
            cursor: pointer;
            background-color: darkblue;
            color: white;
            width: fit-content;
            transition: background-color 0.3s ease-in-out;
            align-self: center;

            &:hover {
                background-color: white;
                color: darkblue;
            }
        }
    }
`


const MRecentBlogPosts = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 30px;
    margin-top: 60px;
    margin-bottom: 160px !important;
    padding-left: 10px;
    padding-right: 10px;


    > h2 {
        font-weight: 300;
        font-family: 'Quicksand';
        margin: 10px 0 25px 15px;
        width: 100%;
        align-self: start;
    }

    > div {
        > h2 {
            font-weight: 300;
            font-family: 'Quicksand';
        }
    }
`