import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/cr-chart1.jpeg'
import img2 from '../../../media/cr-chart2.jpeg'
import img3 from '../../../media/cr-chart3.jpeg'
import img4 from '../../../media/cr-chart4.jpeg'

function CanonicalTags({ sWidth, title, desc, author, date }) {
    const category = 'seo&technical seo'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Discover the importance of using canonical tags for alternate pages to avoid duplicate content issues and improve your SEO. Follow our guide to implement them correctly." />
        <title>Alternate Pages and Proper Canonical Tag Use for SEO</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            
            <p>When managing multiple versions of a web page, it’s crucial to use the canonical tag correctly to avoid issues with duplicate content. This is where the concept of an "alternate page with proper canonical tag" comes into play. In this guide, I'll walk you through what an alternate page is, why the canonical tag is important, and how to implement it properly. Understanding and applying these principles correctly can greatly improve your site's SEO performance and avoid confusion for search engines. Let’s dive in!</p>

<h2>What Is an Alternate Page?</h2>

<p>An alternate page refers to different versions of a web page that contain similar or duplicate content but are meant for different contexts or audiences. For instance, a website might have distinct versions of a product page tailored for various languages or regions. Each version provides a localized experience but is essentially the same page with slight modifications to suit different audiences.</p>

<p><strong>Example:</strong> Imagine you operate an e-commerce site that features a product page in English at <code>https://www.example.com/product-page</code> and another version in French at <code>https://www.example.com/page-produit</code>. Both pages showcase the same product but cater to different linguistic audiences. Here, both pages are alternate versions of the same content.</p>

<h2>Why Use the Canonical Tag?</h2>

<p>The canonical tag is a crucial tool for managing duplicate content issues. It helps search engines understand which version of a page is the preferred or "canonical" one. Without proper canonicalization, search engines might struggle to determine the most relevant page to index and rank, leading to potential issues such as diluted ranking signals and reduced visibility for your preferred page.</p>

<p><strong>Example:</strong> Continuing with our e-commerce scenario, if you have both the English and French versions of your product page, you should use a canonical tag on the French version to point to the English page as the primary source. This tells search engines that the English page is the authoritative version, ensuring it receives the main ranking and indexing focus.</p>

<h2>How to Implement the Canonical Tag</h2>

<p>Proper implementation of the canonical tag involves a few key steps. Here’s how you can do it:</p>

<h3>Identifying the Canonical URL</h3>

<p>Begin by identifying which version of the page should be considered the canonical one. This is usually the page with the most comprehensive content or the one that best serves the target audience. In most cases, this is the page you want to prioritize in search engine results.</p>

<h3>Adding the Canonical Tag</h3>

<p>Once you've determined the canonical URL, you need to add the canonical tag to the HTML of your alternate page. This tag should be included in the <code>&lt;head&gt;</code> section of your HTML document. Here’s the format for the canonical tag:</p>

<pre><code>&lt;link rel="canonical" href="https://www.example.com/primary-page"&gt;</code></pre>

<p>Replace <code>https://www.example.com/primary-page</code> with the URL of your canonical page. This tells search engines to treat this URL as the preferred version.</p>

<h3>Example Implementation</h3>

<p>If your primary product page is <code>https://www.example.com/product-page</code>, and you have a French version at <code>https://www.example.com/page-produit</code>, include the following canonical tag in the French version:</p>

<pre><code>&lt;link rel="canonical" href="https://www.example.com/product-page"&gt;</code></pre>

<p>This tag should be placed within the <code>&lt;head&gt;</code> section of the French product page’s HTML code.</p>

<h2>Common Mistakes to Avoid</h2>

<p>While implementing the canonical tag, be aware of common pitfalls:</p>

<ul>
    <li><strong>Incorrect URL:</strong> Ensure the URL in the canonical tag is accurate and points to the correct canonical page. An incorrect URL can lead to improper indexing and ranking issues.</li>
    <li><strong>Canonical Self-Referencing:</strong> Avoid using a canonical tag on the primary page that points to itself. This is unnecessary and doesn’t provide additional value to search engines.</li>
    <li><strong>Inconsistent Canonical Tags:</strong> Ensure that all alternate versions of a page use consistent canonical tags pointing to the same primary URL. Inconsistencies can create confusion for search engines and affect your SEO performance.</li>
</ul>

<h2>Best Practices for Canonical Tag Implementation</h2>

<p>To ensure you’re leveraging the canonical tag effectively, follow these best practices:</p>

<ul>
    <li><strong>Review and Update Regularly:</strong> Regularly review your canonical tags, especially when updating or adding new pages. Ensure that all alternate pages have up-to-date canonical tags pointing to the correct primary pages.</li>
    <li><strong>Use Absolute URLs:</strong> Always use absolute URLs (including the full domain) in your canonical tags. This avoids potential issues with relative URLs and ensures consistency.</li>
    <li><strong>Monitor and Test:</strong> Use tools like Google Search Console to monitor how search engines are interpreting your canonical tags. Testing and validation can help you catch any issues early.</li>
</ul>

<h2>Wrap-Up</h2>

<p>Implementing the canonical tag correctly is essential for managing alternate pages and avoiding duplicate content issues. By identifying the preferred version of your pages and using the canonical tag to point to it, you help search engines understand which page to index and rank. This practice not only improves your SEO performance but also ensures a better user experience by providing clear, authoritative content. With the guidelines provided in this guide, you can effectively manage your alternate pages and enhance your site's search engine visibility.</p>


        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>

        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            
            <p>When managing multiple versions of a web page, it’s crucial to use the canonical tag correctly to avoid issues with duplicate content. This is where the concept of an "alternate page with proper canonical tag" comes into play. In this guide, I'll walk you through what an alternate page is, why the canonical tag is important, and how to implement it properly. Understanding and applying these principles correctly can greatly improve your site's SEO performance and avoid confusion for search engines. Let’s dive in!</p>

<h2>What Is an Alternate Page?</h2>

<p>An alternate page refers to different versions of a web page that contain similar or duplicate content but are meant for different contexts or audiences. For instance, a website might have distinct versions of a product page tailored for various languages or regions. Each version provides a localized experience but is essentially the same page with slight modifications to suit different audiences.</p>

<p><strong>Example:</strong> Imagine you operate an e-commerce site that features a product page in English at <code>https://www.example.com/product-page</code> and another version in French at <code>https://www.example.com/page-produit</code>. Both pages showcase the same product but cater to different linguistic audiences. Here, both pages are alternate versions of the same content.</p>

<h2>Why Use the Canonical Tag?</h2>

<p>The canonical tag is a crucial tool for managing duplicate content issues. It helps search engines understand which version of a page is the preferred or "canonical" one. Without proper canonicalization, search engines might struggle to determine the most relevant page to index and rank, leading to potential issues such as diluted ranking signals and reduced visibility for your preferred page.</p>

<p><strong>Example:</strong> Continuing with our e-commerce scenario, if you have both the English and French versions of your product page, you should use a canonical tag on the French version to point to the English page as the primary source. This tells search engines that the English page is the authoritative version, ensuring it receives the main ranking and indexing focus.</p>

<h2>How to Implement the Canonical Tag</h2>

<p>Proper implementation of the canonical tag involves a few key steps. Here’s how you can do it:</p>

<h3>Identifying the Canonical URL</h3>

<p>Begin by identifying which version of the page should be considered the canonical one. This is usually the page with the most comprehensive content or the one that best serves the target audience. In most cases, this is the page you want to prioritize in search engine results.</p>

<h3>Adding the Canonical Tag</h3>

<p>Once you've determined the canonical URL, you need to add the canonical tag to the HTML of your alternate page. This tag should be included in the <code>&lt;head&gt;</code> section of your HTML document. Here’s the format for the canonical tag:</p>

<p style={{width: '95%'}}><code>&lt;link rel="canonical" href="https://www.example.com/primary-page"&gt;</code></p>

<p>Replace <code>https://www.example.com/primary-page</code> with the URL of your canonical page. This tells search engines to treat this URL as the preferred version.</p>

<h3>Example Implementation</h3>

<p>If your primary product page is <code>https://www.example.com/product-page</code>, and you have a French version at <code>https://www.example.com/page-produit</code>, include the following canonical tag in the French version:</p>

<p style={{width: '95%'}}><code>&lt;link rel="canonical" href="https://www.example.com/product-page"&gt;</code></p>

<p>This tag should be placed within the <code>&lt;head&gt;</code> section of the French product page’s HTML code.</p>

<h2>Common Mistakes to Avoid</h2>

<p>While implementing the canonical tag, be aware of common pitfalls:</p>

<ul>
    <li><strong>Incorrect URL:</strong> Ensure the URL in the canonical tag is accurate and points to the correct canonical page. An incorrect URL can lead to improper indexing and ranking issues.</li>
    <li><strong>Canonical Self-Referencing:</strong> Avoid using a canonical tag on the primary page that points to itself. This is unnecessary and doesn’t provide additional value to search engines.</li>
    <li><strong>Inconsistent Canonical Tags:</strong> Ensure that all alternate versions of a page use consistent canonical tags pointing to the same primary URL. Inconsistencies can create confusion for search engines and affect your SEO performance.</li>
</ul>

<h2>Best Practices for Canonical Tag Implementation</h2>

<p>To ensure you’re leveraging the canonical tag effectively, follow these best practices:</p>

<ul>
    <li><strong>Review and Update Regularly:</strong> Regularly review your canonical tags, especially when updating or adding new pages. Ensure that all alternate pages have up-to-date canonical tags pointing to the correct primary pages.</li>
    <li><strong>Use Absolute URLs:</strong> Always use absolute URLs (including the full domain) in your canonical tags. This avoids potential issues with relative URLs and ensures consistency.</li>
    <li><strong>Monitor and Test:</strong> Use tools like Google Search Console to monitor how search engines are interpreting your canonical tags. Testing and validation can help you catch any issues early.</li>
</ul>

<h2 style={{marginTop: '50px'}}>Wrap-Up</h2>

<p>Implementing the canonical tag correctly is essential for managing alternate pages and avoiding duplicate content issues. By identifying the preferred version of your pages and using the canonical tag to point to it, you help search engines understand which page to index and rank. This practice not only improves your SEO performance but also ensures a better user experience by providing clear, authoritative content. With the guidelines provided in this guide, you can effectively manage your alternate pages and enhance your site's search engine visibility.</p>


        </div>
    </MContainer>
    }

    </>
  )
}

export default CanonicalTags


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 700px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: 400;
            margin-left: 10px;
            width: 90%;
        }

        > h3 {
            font-weight: normal;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`